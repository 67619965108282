<template>
  <!-- background white -->
  <div class="bg-white">

    <!-- Fixed max-width wrapper -->
    <div class="delimiter">
      <!-- About us -->
      <section
        class="
        xl:pb-28 xl:pt-60 xl:px-44
        lg:pb-20 lg:pt-40 lg:px-32
        md:pb-20 md:pt-32 md:px-24
        sm:px-12
        pb-10 pt-32 px-8"
      >
        <h1
          class="uppercase font-display enter-about enter-fade-up
          lg:text-9xl
          md:text-8xl
          text-7xl"
        >
          {{ $t('about.aboutUs') }}
        </h1>
        <p
          class="font-semibold about-paragraph mt-14 enter-about enter-fade-up
          lg:text-5xl lg:leading-tight
          md:text-4xl md:leading-snug
          text-3xl leading-snug"
          v-html="$t('about.theFirst')"
        ></p>
      </section>
    </div>

    <!-- at trends lab... -->
    <section
      class="relative overflow-hidden
        xl:px-44 
        lg:pl-32 lg:pr-16 
        md:pl-24 md:pr-16
        sm:px-12
        px-8 pb-56"
    >
      <img
        src="@/assets/img/ciudad-camaleonica.png"
        alt="Camaleon City"
        class="img-camaleon enter-about enter-camaleon"
      />

      <div class="delimiter flex justify-end">
        <div
          class="about-paragraph enter-about enter-fade-up
            md:mt-0
            mt-8"
        >
          <p
            class="font-extralight
              lg:text-5xl lg:leading-tight
              md:text-4xl md:leading-snug
              text-3xl leading-snug"
            v-html="$t('about.atTrendsLab')"
          ></p>
          <router-link
            class="inline-block bg-gray-500 text-white rounded-full py-4 px-8 mt-8"
            to="/contact"
          >
            {{ $t('about.getInTouch') }}
          </router-link>
        </div>
      </div>
    </section>

    <!-- About video -->
    <div class="delimiter flex justify-center my-44">
      <video class="w-3/4" controls>
        <source src="@/assets/vid/about-video.mp4" type="video/mp4">
      </video>
    </div>

    <div class="delimiter">

      <!-- gallery carousel -->

      <img src="@/assets/img/logo.svg" alt="Trends Lab" class="trends-logo">
      
      <div class="h-36"></div>
    </div>
  </div>
</template>

<script>

import founded1 from '@/assets/img/founded/founded1.png'
import founded2 from '@/assets/img/founded/founded2.jpg'
import founded3 from '@/assets/img/founded/founded3.jpg'
import founded4 from '@/assets/img/founded/founded4.jpeg'
import founded5 from '@/assets/img/founded/founded5.jpg'
import founded6 from '@/assets/img/founded/founded6.jpg'

import iconMuted from '@/assets/img/volume-mute-filled.svg'
import iconUnmuted from '@/assets/img/volume-up-filled.svg'

import '../assets/css/enter-animations.css'
import initEnterAnimations from '../assets/js/enter-animations'

import { store } from '../main'

// Constants
import { GRAY_PLACEHOLDER } from '../shared/constants'

export default {
  name: 'About',
  data: () => ({
    carouselItems: [founded1, founded2, founded3, founded4, founded5, founded6],
    fullwatchSelected: '',
    muted: true,
    iconMuted,
    iconUnmuted,

    placeholder: GRAY_PLACEHOLDER,

    sharedState: store.state,
  }),
  watch: {
    'sharedState.routerAnimationEnd': function(newValue) {
      if (newValue) this.$refs.video.play()
    },
  },
  mounted() {
    this.disposeEnterAnimations = initEnterAnimations('.enter-about')
  },
  unmounted() {
    this.lazyLoadDispose && this.lazyLoadDispose()
    this.disposeEnterAnimations()
  },
}
</script>

<style scoped>
.trends-logo {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.about-paragraph {
  width: 100%;
}
.img-camaleon {
  position: relative;
  top: 16px;
  left: -5px;
  height: 350px;
  object-fit: contain;
  transform: rotate(32deg);
}
@media (min-width: 480px) {
  .img-camaleon {
    top: 0;
    left: 16px;
    height: 591px;
  }
}
@media (min-width: 768px) {
  .img-camaleon {
    position: absolute;
    top: 30px;
    left: calc(-653px + 50vw);
    height: 695px;
  }
  .about-paragraph {
    width: 457px;
  }
}
@media (min-width: 1024px) {
  .img-camaleon {
    position: absolute;
    top: 62px;
    left: calc(-835px + 50vw);
    height: 830px;
  }
  .about-paragraph {
    width: 645px;
  }
}
@media (min-width: 1440px) {
  .img-camaleon {
    top: 62px;
    left: -175px;
    height: 1019px;
  }
}

.enter-camaleon {
  transform: translateX(-100%) rotate(32deg);
  transition: transform 0.6s ease;
  will-change: transform, opacity;
}
.enter-camaleon.show {
  transform: translateX(0) rotate(32deg);
}
</style>
